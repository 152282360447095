<template>
  <div class="sale-card-page">
    <en-table-layout :toolbar-two="true" :table-data="tableData.data" :span-method="spanMethod" ref="enTableLayout"
      @selection-change="handleSelectionChange" :row-key="getRowKeys" :stripe="false" :highlight-current-row="false">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="销售订单记录" name="saleOrder"></el-tab-pane>
          <el-tab-pane label="销售退单记录" name="saleRefund"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item label="付款状态" class="col-auto" v-if="activeName === 'saleOrder'">
          <el-select style="width: 110px" v-model="searchForm.pay_status" size="medium" clearable>
            <el-option v-for="item in payType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="退款状态" class="col-auto" v-if="activeName === 'saleRefund'">
          <el-select style="width: 110px" v-model="searchForm.refund_status" size="medium" clearable>
            <el-option v-for="item in refundType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto" v-if="activeName === 'saleOrder'">
          <el-select style="width: 110px" v-model="searchForm.trade_status" size="medium" clearable>
            <el-option v-for="item in tradeType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto" v-if="activeName === 'saleRefund'">
          <el-select style="width: 110px" v-model="searchForm.status" size="medium" clearable>
            <el-option v-for="item in tradeType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" class="col-auto" :error="cardRangeError">
          <el-input style="width: 150px" v-model.trim="searchForm.card_code_start" clearable @blur="handleCardRange" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 150px" v-model.trim="searchForm.card_code_end" clearable @blur="handleCardRange" />
        </el-form-item>
        <el-form-item label="单据日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="timeRange" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
      </template>
      <template slot="toolbar-two">
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 110px" v-model="searchForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="item in filterdouble" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-input style="width: 360px" v-model.trim="keywords" placeholder="请输入关键词" clearable>
            <el-select style="width: 130px" v-model="search_type" slot="prepend">
              <el-option label="销售单据编号" value="trade_sn" v-if="activeName === 'saleOrder'"></el-option>
              <el-option label="销售退单编号" value="sn" v-if="activeName === 'saleRefund'"></el-option>
              <el-option label="关联销售单号" value="sell_trade_sn" v-if="activeName === 'saleRefund'"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="联系人名称" value="link_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="制单人名称" value="make_order_name"></el-option>
              <el-option label="备注信息" value="remark"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('search')">搜索</el-button>
        <el-button size="small" type="primary" v-if="activeName === 'saleOrder'"
          @click="saleCardRefreshPage('handleRouteChange')">新增销售记录</el-button>
        <el-button size="small" type="primary" v-if="activeName === 'saleRefund'"
          @click="saleCardRefreshPage('handleRouteChange')">新增退单记录</el-button>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 0)">导出全部</el-button>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 1)">导出勾选项</el-button>
      </template>
      <template slot="table-columns" v-if="activeName === 'saleOrder'">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column label="销售单据编号" width="160" prop="trade_sn" fixed />
        <el-table-column label="所属商城" width="160" prop="shop_name" fixed show-overflow-tooltip />
        <el-table-column label="开卡数量" prop="card_num" />
        <el-table-column label="优惠后金额" prop="after_discount_price" width="100">
          <template slot-scope="scope">
            {{
              scope.row.after_discount_price
              ? round(scope.row.after_discount_price)
              : "0" | unitPrice("￥")
            }}
          </template>
        </el-table-column>
        <el-table-column label="应用类型" width="110">
          <template slot-scope="scope">
            {{ getAppTypeName(scope.row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="client_name" width="110" show-overflow-tooltip />
        <el-table-column label="付款状态" prop="pay_status">
          <template slot-scope="scope">
            {{ getPayTypeName(scope.row.pay_status) }}
          </template>
        </el-table-column>
        <el-table-column label="单据状态" prop="trade_status">
          <template slot-scope="scope">
            {{ getTradeTypeName(scope.row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column label="销售人员" prop="sell_name" show-overflow-tooltip />
        <el-table-column label="制单人" prop="make_order_name" show-overflow-tooltip />
        <el-table-column label="单据日期" prop="create_time" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column label="备注信息" prop="remark" width="180" show-overflow-tooltip />
        <el-table-column label="操作" fixed="right" width="210">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="saleCardRefreshPage('printDemo', scope.row.id)">
              打印
            </el-button>

            <template v-if="scope.row.pay_status === 0
              &&
              scope.row.trade_status === 0
              &&
              scope.row.open_card_status === 0
              ">
              <el-button type="primary" size="mini" @click="getDetail(0, scope.row.id, scope.row)">修改</el-button>
              <el-button type="danger" size="mini"
                @click="saleCardRefreshPage('cancelSaleOrder', scope.row.id)">取消</el-button>
            </template>

            <template v-if="checkSaleOrderStatus(scope.row)">
              <el-button type="primary" size="mini" @click="getDetail(0, scope.row.id, scope.row, true)">修改</el-button>
            </template>


            <template v-if="scope.row.pay_status === 2 &&
              scope.row.trade_status === 2 &&
              scope.row.open_card_status === 1
              ">
              <el-button type="primary" size="mini" @click="getDetail(1, scope.row.id)">查看</el-button>
            </template>


            <template v-if="scope.row.pay_status === 0 &&
              scope.row.trade_status === 3 &&
              scope.row.open_card_status === 0
              ">
              <el-button type="primary" size="mini" @click="getDetail(1, scope.row.id)">查看</el-button>
            </template>
          </template>
        </el-table-column>
      </template>
      <template slot="table-columns" v-if="activeName === 'saleRefund'">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column label="销售退单编号" width="150" prop="sn" fixed />
        <el-table-column label="关联销售单号" width="150" prop="sell_trade_sn" fixed />
        <el-table-column label="所属商城" width="100" prop="shop_name" show-overflow-tooltip />
        <el-table-column label="退卡数量" prop="card_num" />
        <el-table-column label="优惠后金额" prop="after_discount_price" width="100">
          <template slot-scope="scope">
            {{
              scope.row.after_discount_price
              ? round(scope.row.after_discount_price)
              : "0" | unitPrice("￥")
            }}
          </template>
        </el-table-column>
        <el-table-column label="应用类型" width="110">
          <template slot-scope="scope">
            {{ getAppTypeName(scope.row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="client_name" width="110" show-overflow-tooltip />
        <el-table-column label="退款状态" prop="refund_status">
          <template slot-scope="scope">
            {{ getRefundTypeName(scope.row.refund_status) }}
          </template>
        </el-table-column>
        <el-table-column label="单据状态" prop="status">
          <template slot-scope="scope">
            {{ getTradeTypeName(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column label="销售人员" prop="sell_name" show-overflow-tooltip />
        <el-table-column label="制单人" prop="make_order_name" show-overflow-tooltip />
        <el-table-column label="单据日期" prop="create_time" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column label="备注信息" prop="remark" width="180" show-overflow-tooltip />
        <el-table-column label="操作" fixed="right" width="210">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="saleCardRefreshPage('printDemo', scope.row.id)">
              打印
            </el-button>

            <template v-if="scope.row.refund_status == 0 &&
              scope.row.status == 0 &&
              scope.row.close_status == 0">
              <el-button type="primary" size="mini" @click="getDetail(0, scope.row.id, scope.row)">修改</el-button>
              <el-button type="danger" size="mini"
                @click="saleCardRefreshPage('cancelSaleRefund', scope.row.id)">取消</el-button>
            </template>

            <template v-else>
              <el-button type="primary" size="mini" @click="getDetail(1, scope.row.id)">查看</el-button>
            </template>

          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="saleCardRefreshPage('handlePageSizeChange', $event)"
        @current-change="saleCardRefreshPage('handlePageCurrentChange', $event)" :current-page="tableData.page_no"
        :page-size="tableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total" />
    </en-table-layout>
    <vue-easy-print tableShow ref="easyPrint" v-show="false">
      <template slot-scope="func">
        <print-template :table-data="printTableData"></print-template>
      </template>
    </vue-easy-print>
  </div>
</template>

<script>
import * as API_SaleOrder from "@/api/saleCard";
import { getSaleRefundList } from "@/api/saleCard";
import * as API_Sale from "@/api/saleCard";
import * as API_order from "@/api/order";
import { Foundation } from "../../../../ui-utils";
import { downloadExcel } from "@/utils";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import {
  changeDataList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";

export default {
  name: "saleCardOpenManagement",
  mixins: [mixins],
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      activeName: "saleOrder",
      timeRange: "",
      keywords: "",
      search_type: "trade_sn",
      searchForm: {
        pay_status: "",
        refund_status: "",
        trade_status: "",
        card_code_start: "",
        card_code_end: "",
        create_time_start: "",
        create_time_end: "",
        app_type_shop_type_flag: "",
        keywords: "",
        status: "",
      },
      cardRangeError: "",
    };
  },
  activated () {
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  computed: {
    filterdouble () {
      if (!this.double_choice_mall_open) {
        this.cardTypes.splice(2, 1)
      }
      return this.cardTypes;
    }
  },
  mounted () {
    API_order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  methods: {
    spanMethod (opt) {
      switch (this.activeName) {
        case "saleOrder":
          return spanMethod(opt, [2, 3, 4]);
        case "saleRefund":
          return spanMethod(opt, [3, 4, 5]);
        default:
          break;
      }
    },
    // 卡号区间校验
    handleCardRange () {
      /** 判断输入的卡号是否包含前缀 **/
      const cardReg = /^[a-zA-Z]+\d{3,}$|^\d{3,}$/;
      const letterReg = /^[a-zA-Z]+\d+$/;
      if (this.searchForm.card_code_start && this.searchForm.card_code_end) {
        if (
          cardReg.test(this.searchForm.card_code_start) &&
          cardReg.test(this.searchForm.card_code_end)
        ) {
          // 包含前缀
          if (
            letterReg.test(this.searchForm.card_code_start) &&
            letterReg.test(this.searchForm.card_code_end)
          ) {
            const startPrefix = /[a-zA-Z]+/.exec(
              this.searchForm.card_code_start
            )[0];
            const endPrefix = /[a-zA-Z]+/.exec(
              this.searchForm.card_code_end
            )[0];
            if (startPrefix && endPrefix) {
            }
            if (startPrefix !== endPrefix) {
              this.cardRangeError = "当前卡号区间格式不一致。";
            } else if (startPrefix.length > 5 || endPrefix.length > 5) {
              this.cardRangeError =
                "当前卡号区间格式不正确，请重新填写卡号区间。";
            } else {
              // 判断数组部分大于3位是否以0开头
              // const startNum = /\d+/.exec(this.searchForm.card_code_start)[0];
              // const endNum = /\d+/.exec(this.searchForm.card_code_end)[0];
              // if (/^0\d{3,}/.test(startNum) || /^0\d{3,}/.test(endNum)) {
              //   this.cardRangeError =
              //     "当前卡号区间格式不正确，请重新填写卡号区间。";
              // } else {
              this.cardRangeError = "";
              // }
            }
          } else if (
            (letterReg.test(this.searchForm.card_code_start) &&
              !letterReg.test(this.searchForm.card_code_end)) ||
            (!letterReg.test(this.searchForm.card_code_start) &&
              letterReg.test(this.searchForm.card_code_end))
          ) {
            this.cardRangeError = "当前卡号区间格式不一致。";
          } else {
            if (
              parseInt(this.searchForm.card_code_end) <
              parseInt(this.searchForm.card_code_start)
            ) {
              this.cardRangeError =
                "结束框卡号数字部分不能小于起始输入框卡号数字部分。";
            } else if (
              /^0\d{3,}/.test(this.searchForm.card_code_end) ||
              /^0\d{3,}/.test(this.searchForm.card_code_start)
            ) {
              // 满足3位以上数字且以0开头
              // this.cardRangeError =
              //   "当前卡号区间格式不正确，请重新填写卡号区间。";
              this.cardRangeError = "";
            } else {
              this.cardRangeError = "";
            }
          }
        } else {
          this.cardRangeError = "当前卡号区间格式不正确，请重新填写卡号区间。";
        }
      } else if (
        !this.searchForm.card_code_start &&
        !this.searchForm.card_code_end
      ) {
        this.cardRangeError = "";
      } else {
        this.cardRangeError = "请输入正确卡号区间进行搜索";
      }
    },
    clearSearchForm () {
      this.searchForm = {
        pay_status: "",
        refund_status: "",
        trade_status: "",
        card_code_start: "",
        card_code_end: "",
        create_time_start: "",
        create_time_end: "",
        app_type_shop_type_flag: "",
        keywords: "",
        status: "",
      };
      this.keywords = "";
    },
    handleClick (e) {
      this.search_type = this.activeName == "saleOrder" ? "trade_sn" : "sn";
      this.params = {
        page_no: 1,
        page_size: 20,
      };
      this.clearSearchForm();
      this.tableData.data = [];
      this.$refs.enTableLayout.clearSelected();
      this.getList();
    },
    search () {
      if (this.cardRangeError) {
        this.$message.error("请先输入正确的卡号区间再搜索");
        return;
      }
      if (
        this.searchForm.card_code_start &&
        this.searchForm.card_code_end &&
        (this.searchForm.status ||
          this.searchForm.keywords ||
          this.order_time_range ||
          this.searchForm.pay_status ||
          this.searchForm.trade_status ||
          this.searchForm.app_type_shop_type_flag ||
          this.searchForm.refund_status)
      ) {
        this.$message.error("卡号区间搜索不能和其他筛选条件同时使用");
        return;
      }
      this.params = {
        page_no: this.params.page_no,
        page_size: this.params.page_size,
        ...this.searchForm,
      };
      if (this.timeRange) {
        this.params["create_time_start"] = this.timeRange[0].getTime() / 1000;
        this.params["create_time_end"] =
          this.timeRange[1].getTime() / 1000 + 86399;
      }
      if (this.keywords) {
        this.params[this.search_type] = this.keywords;
      } else {
        delete this.params[this.search_type];
      }
      if (!this.searchForm.card_code_start || !this.searchForm.card_code_end) {
        delete this.params["card_code_start"];
        delete this.params["card_code_end"];
      }
      this.getList();
    },
    getList () {
      const params = {
        ...this.params
      }
      if (this.activeName === "saleOrder") {
        API_SaleOrder.getSaleOrderList(params).then((res) => {
          this.tableData = res;
          this.tableData.data = changeDataList(
            res.data,
            "shop_trade_sell_list",
            ["shop_name", "card_num", "after_discount_price"]
          );
          this.$nextTick(() => {
            this.$refs.enTableLayout.$refs.table.doLayout();
          });
        });
      } else {
        API_SaleOrder.getSaleRefundList(params).then((res) => {
          this.tableData = res;
          this.tableData.data = changeDataList(
            res.data,
            "shop_refund_record_list",
            ["shop_name", "card_num", "after_discount_price"]
          );
          this.$nextTick(() => {
            this.$refs.enTableLayout.$refs.table.doLayout();
          });
        });
      }
    },
    // 跳转销售订单
    handleRouteChange () {
      this.$store.dispatch("addVisitedViews", this.$route);
      if (this.activeName === "saleOrder") {
        this.$router.push({
          name: "addSaleOrderRecord",
        });
      } else {
        this.$router.push({
          name: "addSaleRefundRecord",
        });
      }
    },
    // 打印
    printDemo (id) {
      if (this.activeName === "saleOrder") {
        API_Sale.getSaleOrderDetail(id).then((res) => {
          const { trade_sell_do, trade_sell_card_dos, app_type_shop_type_flag } = res;
          trade_sell_do.app_type_shop_type_flag = app_type_shop_type_flag
          this.handlePrintTable(trade_sell_do, trade_sell_card_dos, 1);
        });
      } else {
        API_Sale.getSaleRefundDetail(id).then((res) => {
          const { refund_record_sell_do, refund_sell_card_dos, app_type_shop_type_flag } = res;
          refund_record_sell_do.app_type_shop_type_flag = app_type_shop_type_flag
          this.handlePrintTable(refund_record_sell_do, refund_sell_card_dos, 2);
        });
      }
    },
    // 导出
    exportCardList (type) {
      // 0 导出全部 1 导出选中
      let params = {
        ...this.params,
      };
      params.page_no = 1;
      params.page_size = 999999;
      if (type === 1) {
        if (!this.multipleSelection.length) {
          this.$message.error("请先选择要导出的数据");
          return false;
        }
        params["ids"] = this.multipleSelection.map((item) => {
          return item.id;
        });
      }
      if (this.activeName === "saleOrder") {
        API_SaleOrder.getSaleOrderList(params).then((res) => {
          let list = changeDataList(res.data, "shop_trade_sell_list", [
            "shop_name",
            "card_num",
            "after_discount_price",
          ]);
          let merges = [];
          let wscols = [20, 20, 10, 10, 10, 10, 10, 10, 12, 12, 20, 20, 20].map(
            (n) => {
              return {
                wch: n,
              };
            }
          );
          let data = list.map((item, index) => {
            if (item.span > 1) {
              let arr = new Array(12)
                .fill(0)
                .map((a, i) => {
                  if (i == 1 || i == 2 || i == 3) return null;
                  return {
                    s: { r: index + 1, c: i },
                    e: { r: index + item.span, c: i },
                  };
                })
                .filter((a) => a);
              merges.push(...arr);
            }
            return {
              销售单据编号: item.trade_sn,
              所属商城: item.shop_name,
              开卡数量: item.card_num,
              优惠后金额: item.after_discount_price,
              应用类型: this.getAppTypeName(item.app_type_shop_type_flag),
              客户名称: item.client_name,
              付款状态: this.getPayTypeName(item.pay_status),
              单据状态: this.getTradeTypeName(item.trade_status),
              销售人员: item.sell_name,
              制单人: item.make_order_name,
              单据日期: Foundation.unixToDate(item.create_time),
              备注信息: item.payable_remark,
            };
          });
          downloadExcel({ data, merges, wscols }, "销售订单记录列表");
          this.$refs.enTableLayout.clearSelected();
        });
      } else {
        API_SaleOrder.getSaleRefundList(params).then((res) => {
          let list = changeDataList(res.data, "shop_refund_record_list", [
            "shop_name",
            "card_num",
            "after_discount_price",
          ]);
          let merges = [];
          let wscols = [20, 20, 20, 10, 10, 10, 10, 10, 12, 12, 20, 20, 20].map(
            (n) => {
              return {
                wch: n,
              };
            }
          );
          let data = list.map((item) => {
            if (item.span > 1) {
              let arr = new Array(13)
                .fill(0)
                .map((a, i) => {
                  if (i == 2 || i == 3 || i == 4) return null;
                  return {
                    s: { r: index + 1, c: i },
                    e: { r: index + item.span, c: i },
                  };
                })
                .filter((a) => a);
              merges.push(...arr);
            }
            console.log(item.shop_name);
            return {
              销售退单编号: item.sn,
              关联销售单号: item.sell_trade_sn,
              所属商城: item.shop_name,
              退卡数量: item.card_num,
              优惠后金额: item.after_discount_price,
              应用类型: this.getAppTypeName(item.app_type_shop_type_flag),
              客户名称: item.client_name,
              退款状态: this.getRefundTypeName(item.refund_status),
              单据状态: this.getTradeTypeName(item.status),
              销售人员: item.sell_name,
              制单人: item.make_order_name,
              单据日期: Foundation.unixToDate(item.create_time),
              备注信息: item.remark,
            };
          });
          downloadExcel({ data, merges, wscols }, "销售退单记录列表");
          this.$refs.enTableLayout.clearSelected();
        });
      }
    },
    // 获取详情
    getDetail (type, id, row, disabled = false) {
      // type 0 修改  1查看
      this.$store.dispatch("addVisitedViews", this.$route);
      let routeData = [
        {
          saleOrder: {
            name: "addSaleOrderRecord",
            query: {
              id: id,
              disabled
            },
          },
          saleRefund: {
            name: "addSaleRefundRecord",
            query: {
              id: id,
              disabled
            },
          },
        },
        {
          saleOrder: {
            path: "/tools/sale-order-detail",
            query: {
              id: id,
            },
          },
          saleRefund: {
            path: "/tools/sale-refund-detail",
            query: {
              id: id,
            },
          },
        },
      ];
      this.$router.push(routeData[type][this.activeName]);
    },
    // 取消销售订单
    cancelSaleOrder (id) {
      this.$confirm("确认要取消吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          API_Sale.cancelOrder(0, id).then((res) => {
            this.$message.success("取消成功");
            this.getList();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消销售退单
    cancelSaleRefund (id) {
      this.$confirm("确认要取消吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          API_Sale.cancelOrder(1, id).then((res) => {
            this.$message.success("取消成功");
            this.getList();
          });
        })
        .catch(() => { });
    },
    checkSaleOrderStatus (row) {
      const { pay_status, trade_status, open_card_status } = row;
      if (pay_status == 1 && trade_status == 1 && open_card_status == 0) return true;
      if (pay_status == 2 && trade_status == 2 && open_card_status == 0) return true;
      if (pay_status == 0 && trade_status == 0 && open_card_status == 1) return true;
      if (pay_status == 1 && trade_status == 1 && open_card_status == 1) return true;
      return false;
    }
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-table tr>td {
  background-color: unset !important;
}

.col-auto {
  /deep/ .el-form-item__error {
    z-index: 8;
  }
}
</style>
